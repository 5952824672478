import { cssJoin } from '@volvo-cars/css/utils';
import { Icon } from '@volvo-cars/react-icons';
import { Track, TrackingProvider } from '@volvo-cars/tracking';
import { CSSProperties } from 'react';
import { Spacer } from 'vcc-ui';

import { Link, TopBarLogo } from '@collab/atoms';
import { SkipNavLink } from '@collab/molecules';
import {
  useMobileMenuActions,
  useMobileMenuContainer,
} from '@collab/organisms/NavMenu';
import { usePageOffset } from '@collab/providers/PageOffsetProvider';

import styles from './Header.module.css';

export type HeaderProps = {
  navItems: LinkItem[];
  withInfoBanner: boolean;
};

type LinkItem = {
  text: string;
  href: string;
  active?: boolean;
};

const Header: React.FC<HeaderProps> = ({ navItems }) => {
  const { setMobileMenuOpen, closeOverlays } = useMobileMenuActions();
  const isMobileMenuOpen = useMobileMenuContainer();

  const { headerOffset } = usePageOffset();

  return (
    <TrackingProvider eventCategory="header">
      <SkipNavLink targetId="main">Skip to content</SkipNavLink>
      <nav
        aria-label="Secondary"
        className={styles.wrapper}
        style={{ '--top': `${headerOffset}px` } as CSSProperties}
      >
        <div className={styles.innerWrapper}>
          <div className={styles.content}>
            <div className={styles.logoWrapper}>
              <TopBarLogo onClick={closeOverlays} />
            </div>
            <MobileMenuButton
              onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
            />
            <div
              className={cssJoin(
                'flex-row text-center gap-32',
                styles.hideOnMobile,
              )}
            >
              {navItems.map(({ href, text, active }, i) => (
                <NavItem key={i} href={href} active={!!active}>
                  {text}
                </NavItem>
              ))}
            </div>
          </div>
        </div>
      </nav>
      <Spacer size={[8, 8, 10]} />
    </TrackingProvider>
  );
};

const NavItem: React.FC<{
  href: string;
  active: boolean;
  children: string;
}> = ({ href, children, active }) => {
  const classNames = cssJoin(styles.navItem, active && styles.navItemActive);

  return (
    <Track eventAction="link|click" eventLabel={href}>
      <Link href={href} className={classNames}>
        <p className="font-medium">{children}</p>
      </Link>
    </Track>
  );
};

const MobileMenuButton: React.FC<{
  onClick: () => void;
}> = ({ onClick }) => (
  <button
    type="button"
    onClick={onClick}
    aria-label="Open menu"
    className="icon-button-clear lg:hidden self-center"
  >
    <Icon icon="menu" size={24} />
  </button>
);

export default Header;

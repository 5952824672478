import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

let previousScrollY = 0;

const useShowToc = (offsetBeforeHiding: number = 80) => {
  const [show, setShow] = useState(true);
  const { events } = useRouter();

  useEffect(() => {
    const setInitialShow = () => setShow(true);

    events.on('routeChangeComplete', setInitialShow);
    return () => {
      events.off('routeChangeComplete', setInitialShow);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const toggleVisibility = () => {
      if (!shouldIgnoreToggle(offsetBeforeHiding)) {
        if (isScrollingDown()) {
          if (show && isBelowOffset(offsetBeforeHiding)) {
            setShow(false);
          }
        } else if (!show) {
          setShow(true);
        }
      }

      previousScrollY = window.scrollY;
    };

    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, [show, offsetBeforeHiding]);

  return show;
};

const shouldIgnoreToggle = (offset: number) =>
  (prevAtTop() && isBelowOffset(offset)) ||
  (isAtTop() && prevBelowOffset(offset));

const isScrollingDown = () => previousScrollY < window.scrollY;
const isBelowOffset = (offset: number) => offset < window.scrollY;
const prevBelowOffset = (offset: number) => offset < previousScrollY;
const isAtTop = () => window.scrollY === 0;
const prevAtTop = () => previousScrollY === 0;

export default useShowToc;

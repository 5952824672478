const ellipsis = '...';

const formatBreadcrumbs = (breadcrumbs: string[]) => {
  const short =
    breadcrumbs.length >= 3
      ? [breadcrumbs[0], ellipsis, breadcrumbs[breadcrumbs.length - 1]]
      : breadcrumbs;

  return short.length > 0 ? short.join('/').split(/(\/)/g) : [];
};

export default formatBreadcrumbs;

import { useRouter } from 'next/router';
import { useEffect } from 'react';

import NavMenuStylesProvider, { NavMenuStyles } from './MenuStyleProvider';
import DesktopMenu from './desktop/DesktopMenu';
import MobileMenu from './mobile/MobileMenu';

const NavMenu: React.FC<{ styles: NavMenuStyles }> = ({ styles }) => {
  const router = useRouter();

  useEffect(() => {
    const handlePopstate = ({ state }: PopStateEvent) => {
      if (state?.tocState) {
        router.push(state.url);
      }
    };

    window.addEventListener('popstate', handlePopstate);

    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <NavMenuStylesProvider styles={styles}>
      <MobileMenu />
      <DesktopMenu />
    </NavMenuStylesProvider>
  );
};

export default NavMenu;

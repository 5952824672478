import React, { createContext } from 'react';

import { sectionMarginTopFromM } from '@collab/style/customThemeTokens';

import { useDesktopMenuStyles } from '../MenuStyleProvider';
import { useHeadingRefs } from '../hooks';
import useScrollSection from '../utils/useScrollSection';

export const ToCSectionContext = createContext<number>(0);

const DesktopMenuProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const desktopStyles = useDesktopMenuStyles();

  const anchorOffset = sectionMarginTopFromM + desktopStyles.topOffset;

  const refs = useHeadingRefs();
  const section = useScrollSection(refs, anchorOffset);

  return (
    <ToCSectionContext.Provider value={section}>
      {children}
    </ToCSectionContext.Provider>
  );
};

export default DesktopMenuProvider;

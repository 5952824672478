const Lock = () => (
  <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.143 4.114v2.743h.457V4.114a4.114 4.114 0 1 0-8.229 0v2.743h.458V4.114a3.657 3.657 0 1 1 7.314 0Z"
      fill="#000"
      fillOpacity=".96"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 14.171v-6.4h10.971v6.4c0 1.01-.818 1.829-1.828 1.829H4.829A1.829 1.829 0 0 1 3 14.171Zm.457 0V8.23h10.057v5.942c0 .758-.614 1.372-1.371 1.372H4.829a1.372 1.372 0 0 1-1.372-1.372Z"
      fill="#000"
      fillOpacity=".96"
    />
  </svg>
);

export default Lock;

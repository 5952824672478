const LinkChevron = () => (
  <svg
    style={{
      top: '1px',
      position: 'relative',
      marginInlineStart: '0.3em',
    }}
    viewBox="0 0 12 12"
    width="0.75em"
    height="0.75em"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m2.728 10 4-4-4-4L4 .729 9.273 6 4 11.273l-1.272-1.272Z"
      fill="currentColor"
    />
  </svg>
);

export default LinkChevron;

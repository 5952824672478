const baseSpace = 8;

export const menuAccountItemHeightMobile = baseSpace * 11; // Also in MenuAccountItem.module.css

export const sectionMarginTopUntilM = baseSpace * 8;
export const sectionMarginTopFromM = baseSpace * 12;

export const sideMargin = baseSpace * 3;

export const headerHeight = {
  untilL: baseSpace * 8,
  fromL: baseSpace * 10,
};

export const tocBarHeight = baseSpace * 7;

export const infoBannerHeight = 56;

export const anchorOffsetUntilL =
  headerHeight.untilL + tocBarHeight + 4 * baseSpace;

export const menuWidth = 340;

export const mobileMenuWidth = 370;

export const CONTENT_MAX_WIDTH = 756;

export const TEXT_BLOCK_MAX_WIDTH = 704;

export const DESKTOP_MIN_WIDTH = 1024;

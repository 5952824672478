import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { menuToggleDuration as mobileMenuToggleDuration } from './mobile/MobileMenu';

type DrawerActionsProps = {
  addDrawer: (id: string) => void;
  removeDrawer: () => void;
  resetDrawerDelayed: () => void;
};

const DrawerActionsContext = createContext<DrawerActionsProps>(
  {} as DrawerActionsProps,
);
const DrawerStateContext = createContext<string[]>([]);

const DrawerProvider: React.FC<{
  initialState: string[];
  children: React.ReactNode;
}> = ({ children, initialState }) => {
  const [drawers, setDrawers] = useState<string[]>(initialState);
  const timer = useRef<null | NodeJS.Timeout>(null);

  useEffect(() => {
    return () => {
      timer.current = null;
    };
  }, []);

  useEffect(() => {
    setDrawers(initialState);
  }, [initialState]);

  const addDrawer = (id: string) => {
    setDrawers((prevState) => [...prevState, id]);
  };

  const removeDrawer = () => {
    setDrawers((prevState) => prevState.slice(0, prevState.length - 1));
  };

  const resetDrawer = () => {
    setDrawers(initialState);
  };

  const resetDrawerDelayed = () => {
    timer.current = setTimeout(resetDrawer, mobileMenuToggleDuration);
  };

  const drawerActions: DrawerActionsProps = {
    addDrawer,
    removeDrawer,
    resetDrawerDelayed,
  };

  return (
    <DrawerActionsContext.Provider value={drawerActions}>
      <DrawerStateContext.Provider value={drawers}>
        {children}
      </DrawerStateContext.Provider>
    </DrawerActionsContext.Provider>
  );
};

export const useDrawers = () => useContext(DrawerStateContext);

export const useDrawerActions = () => useContext(DrawerActionsContext);

export default DrawerProvider;

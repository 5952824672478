import { useContext } from 'react';

import { useMenuState } from '../hooks';
import formatBreadcrumbs from '../utils/formatBreadcrumbs';
import { createParentIds } from '../utils/menuIds';
import {
  MobileMenuActionsContext,
  MobileMenuContainerContext,
  MobileMenuToCContext,
} from './MobileMenuState';

export const useMobileMenuActions = () => useContext(MobileMenuActionsContext);
export const useMobileMenuToC = () => useContext(MobileMenuToCContext);
export const useMobileMenuContainer = () =>
  useContext(MobileMenuContainerContext);

export const useMobileBreadCrumbs = (id: string) => {
  const { itemsFlat } = useMenuState();
  const pageIds = [...createParentIds(id), id];
  const breadCrumbs = pageIds.map((id) => itemsFlat.get(id)?.label as string);

  return formatBreadcrumbs(breadCrumbs);
};

import React, { ReactElement } from 'react';

import {
  headerHeight,
  menuAccountItemHeightMobile,
} from '@collab/style/customThemeTokens';

import styles from './MobileDrawerContainer.module.css';

const Drawer: React.FC<{
  items: ReactElement[];
  withAccount: boolean;
}> = ({ items, withAccount }) => {
  const drawerOffset = withAccount
    ? headerHeight.untilL + menuAccountItemHeightMobile
    : headerHeight.untilL;

  const drawerLevel = items.length - 1;

  return (
    <div
      style={{
        transform: `translateX(-${drawerLevel * 100}%)`,
        top: drawerOffset,
        height: `calc(100% - ${drawerOffset}px)`,
      }}
      className={styles.drawerContainer}
    >
      {items.map((child, index) => (
        <div key={index} className={styles.drawerBlock}>
          {child}
        </div>
      ))}
    </div>
  );
};

export default Drawer;

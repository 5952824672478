import { cssJoin } from '@volvo-cars/css/utils';
import dynamic from 'next/dynamic';
import React, { CSSProperties, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import { useDrawerActions } from '../DrawerProvider';
import { AccountProps } from '../MenuState';
import {
  useAccountItem,
  useIsRootItem,
  useItemGuard,
  useItemState,
  useMenuStateActions,
} from '../hooks';
import {
  ModuleItem,
  PageItem,
  PageTopicItem,
  SectionItem,
  SectionSubItem,
} from '../types';
import styles from './Menu.module.css';
import {
  CategoryLabel,
  CloseModuleLabel,
  ModuleLabel,
  PageLabel,
  PageTopicLabel,
  SectionLabel,
} from './MenuItemLabel';
import { useOpenModule, useToCSection } from './hooks';

const MenuAccountItem = dynamic(() => import('../MenuAccountItem'));

export const PageMenuItem: React.FC<PageItem> = ({
  href,
  id,
  isProtected,
  items,
  label,
}) => {
  const { open, active } = useItemState(id);
  const isRootItem = useIsRootItem(id);
  const { protect, shouldProtect } = useItemGuard(isProtected);

  const onClick = () => {
    if (shouldProtect) {
      protect(href);
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  return (
    <>
      <PageLabel
        active={active}
        href={href}
        isProtected={shouldProtect}
        isRootItem={isRootItem}
        label={label}
        onClick={onClick}
        open={open}
      />
      <ToC items={items} open={open} />
    </>
  );
};

export const AccountItem: React.FC<{
  account: AccountProps;
}> = ({ account }) => {
  const { id, items } = useAccountItem();
  const { open, active } = useItemState(id);

  const onClick = () => {
    if (active) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  return (
    <>
      <MenuAccountItem active={active} onClick={onClick} {...account} />
      <ToC items={items} open={open} />
      <div
        className={styles.accountDivider}
        style={{ '--height': open ? '2px' : 0 } as CSSProperties}
      />
    </>
  );
};

export const ModuleMenuItem: React.FC<ModuleItem> = ({ id, label }) => {
  const { open, active } = useItemState(id);
  const { toggleSubMenuOpen } = useMenuStateActions();
  const isRootItem = useIsRootItem(id);
  const { addDrawer } = useDrawerActions();

  const onClick = () => {
    addDrawer(id);
    toggleSubMenuOpen(id);
  };

  return (
    <ModuleLabel
      active={active}
      isRootItem={isRootItem}
      label={label}
      onClick={onClick}
      open={open}
    />
  );
};

export const SectionMenuItem: React.FC<SectionItem> = ({
  id,
  isProtected,
  items,
  label,
}) => {
  const { open, active } = useItemState(id);
  const { toggleSubMenuOpen } = useMenuStateActions();
  const isRootItem = useIsRootItem(id);
  const { protect, shouldProtect } = useItemGuard(isProtected);

  const firstPage = isRootItem ? getPageItemIfFirst(items) : undefined;

  const onClick = () => {
    if (shouldProtect && firstPage) {
      protect(firstPage.href);
    } else {
      toggleSubMenuOpen(id);
    }
  };

  return (
    <>
      <SectionLabel
        active={active}
        href={firstPage?.href}
        isProtected={shouldProtect}
        isRootItem={isRootItem}
        label={label}
        onClick={onClick}
        open={open}
      />
      <SubMenu open={open}>
        {items.map((item) => {
          if (item.variant === 'page') {
            return <PageMenuItem key={item.id} {...item} />;
          }
          if (item.variant === 'category') {
            return <CategoryLabel key={item.id} label={item.label} />;
          }
          return <ModuleMenuItem key={item.id} {...item} />;
        })}
      </SubMenu>
    </>
  );
};

export const CloseModuleItem = () => {
  const openModule = useOpenModule();
  const { toggleSubMenuOpen } = useMenuStateActions();
  const { removeDrawer } = useDrawerActions();

  const onClick = () => {
    if (openModule) {
      toggleSubMenuOpen(openModule.id);
      removeDrawer();
    }
  };

  return <CloseModuleLabel onClick={onClick} />;
};

const ToC: React.FC<{
  items: PageTopicItem[];
  open: boolean;
}> = ({ items, open }) => {
  const section = useToCSection();

  return (
    <SubMenu open={open}>
      <div
        className={styles.tocSlider}
        style={{ '--active-index': section } as CSSProperties}
      />
      {items.map((item, index) => (
        <PageTopicLabel
          key={item.id}
          active={index === section}
          urlHash={item.href.split('#')[1]}
          label={item.label}
        />
      ))}
    </SubMenu>
  );
};

const SubMenu: React.FC<{
  open: boolean;
  children: React.ReactNode;
}> = ({ open, children }) => {
  const nodeRef = useRef(null);

  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={open}
      timeout={500}
      mountOnEnter
      unmountOnExit
      classNames={{
        enter: styles.subMenuEnter,
        enterActive: styles.subMenuEnterActive,
        exit: styles.subMenuExit,
        exitActive: styles.subMenuExitActive,
      }}
    >
      <div className={styles.subMenuWrapper} ref={nodeRef}>
        <div className={cssJoin(styles.subMenuInner, styles.subMenu)}>
          {children}
        </div>
      </div>
    </CSSTransition>
  );
};

const getPageItemIfFirst = (items: SectionSubItem[]) =>
  items[0]?.variant === 'page' ? items[0] : undefined;

import dynamic from 'next/dynamic';
import { createRef } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { useToastDispatch, useToastState } from './ToastProvider';
import styles from './Toasts.module.css';

const Toast = dynamic(() => import('./Toast/Toast'));

const Toasts = () => {
  const dispatch = useToastDispatch();
  const messageQueue = useToastState();

  const removeMessage = (id: string) => {
    dispatch({ type: 'remove', messageId: id });
  };

  return (
    <span className={styles.wrapper}>
      <TransitionGroup component={null}>
        {messageQueue.map((msg) => {
          const ref = createRef<HTMLDivElement>();

          return (
            <CSSTransition
              in={!!msg}
              classNames={{
                exit: styles.exitToast,
                exitActive: styles.exitActiveToast,
                enter: styles.enterToast,
                enterActive: styles.enterActiveToast,
              }}
              nodeRef={ref}
              key={msg.id}
              timeout={300}
              mountOnEnter
              unmountOnExit
            >
              <div ref={ref}>
                <Toast
                  id={msg.id}
                  text={msg.text}
                  variant={msg.variant}
                  count={msg.count}
                  removeMessage={() => removeMessage(msg.id)}
                />
              </div>
            </CSSTransition>
          );
        })}
      </TransitionGroup>
    </span>
  );
};

export default Toasts;

import React, { useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import { zIndex as zIndexTokens } from '@collab/style/customThemeTokens';
import lockBodyScroll from '@collab/utils/lockBodyScroll';

import styles from './MobileBackdrop.module.css';

const MenuBackdrop: React.FC<{
  show: boolean;
  onClose: () => any;
  hasExistingBackdrop?: boolean;
  zIndex?: number;
}> = ({
  show,
  onClose,
  hasExistingBackdrop = false,
  zIndex = zIndexTokens.mobileMenuBackdrop,
}) => {
  useEffect(() => {
    if (show) {
      lockBodyScroll(true);
    }

    return () => {
      if (!hasExistingBackdrop) {
        lockBodyScroll(false);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!hasExistingBackdrop) {
      lockBodyScroll(show);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);
  const nodeRef = useRef(null);

  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={show}
      timeout={500}
      mountOnEnter
      unmountOnExit
      classNames={{
        enter: styles.backdropEnter,
        enterActive: styles.backdropEnterActive,
        exitActive: styles.backdropExitActive,
      }}
    >
      <button
        type="button"
        className={styles.backdrop}
        ref={nodeRef}
        onClick={onClose}
        style={{ zIndex }}
      />
    </CSSTransition>
  );
};

export default MenuBackdrop;

import { cssJoin } from '@volvo-cars/css/utils';
import { Icon } from '@volvo-cars/react-icons';
import { Track } from '@volvo-cars/tracking';

import { Link } from '@collab/atoms/index';

import Lock from '../icons/Lock';
import styles from './MobileMenu.module.css';

type BaseLabel = {
  isHighlighted: boolean;
  onClick: () => void;
  label: string;
};

type PageLabelType = BaseLabel & {
  href: string | undefined;
  isProtected?: boolean;
};

type ParentLabelType = BaseLabel & { isProtected?: boolean };

export const PageLabel: React.FC<PageLabelType> = ({
  isHighlighted,
  onClick,
  href,
  isProtected,
  label,
}) =>
  href ? (
    <Link
      href={href}
      className={labelButtonClasses(isHighlighted)}
      onClick={onClick}
    >
      <Label isHighlighted={isHighlighted}>{label}</Label>
      {isProtected && <Lock />}
    </Link>
  ) : (
    <button
      type="button"
      onClick={onClick}
      className={labelButtonClasses(isHighlighted)}
    >
      <Label isHighlighted={isHighlighted}>{label}</Label>
      {isProtected && <Lock />}
    </button>
  );

export const ParentLabel: React.FC<ParentLabelType> = ({
  isHighlighted,
  onClick,
  label,
  isProtected,
}) => (
  <button
    type="button"
    onClick={onClick}
    className={labelButtonClasses(isHighlighted)}
  >
    <Label isHighlighted={isHighlighted}>{label}</Label>
    {isProtected ? <Lock /> : <Icon icon="chevron-forward" size={16} />}
  </button>
);

const labelButtonClasses = (isHighlighted: boolean) =>
  cssJoin(
    'flex items-center w-full',
    styles.menuItem,
    isHighlighted && styles.highlighted,
  );

const Label: React.FC<{
  isHighlighted: boolean;
  children: string;
}> = ({ isHighlighted, children }) => (
  <p
    className={cssJoin(
      'flex justify-between items-center w-full h-64 overflow-hidden font-20 border-b',
      isHighlighted && 'font-medium',
    )}
    style={{ marginInlineEnd: 'calc(var(--v-space-16) * -1)' }}
  >
    {children}
  </p>
);

export const ExternalMenuItemLabel: React.FC<{
  href: string;
  label: string;
}> = ({ href, label }) => (
  <Track eventAction="link|click" eventLabel={href}>
    <Link
      href={href}
      className="button-text self-start p-24"
      data-color="accent"
    >
      {label}
    </Link>
  </Track>
);

export const CategoryLabel: React.FC<{
  label: string;
}> = ({ label }) => (
  <p className="font-medium micro uppercase text-secondary pl-24">{label}</p>
);

export const PageTopicLabel: React.FC<{
  active: boolean;
  onClick: () => void;
  label: string;
}> = ({ active, onClick, label }) => (
  <button
    onClick={onClick}
    type="button"
    className={cssJoin(styles.pageTopic, active && styles.active)}
  >
    <p className={cssJoin(active && 'font-medium', styles.pageTopicLabelText)}>
      {label}
    </p>
  </button>
);

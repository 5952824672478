import { createContext, ReactNode, useContext } from 'react';

type PageOffsetContextState = {
  headerOffset: number;
  titleOffset: number;
};

type PageOffsetProviderState = PageOffsetContextState & {
  children: ReactNode;
};

export const PageOffsetContext = createContext({
  headerOffset: 0,
  titleOffset: 0,
});

const PageOffsetProvider: React.FC<PageOffsetProviderState> = ({
  headerOffset,
  titleOffset,
  children,
}) => (
  <PageOffsetContext.Provider value={{ headerOffset, titleOffset }}>
    {children}
  </PageOffsetContext.Provider>
);

export const usePageOffset = () => useContext(PageOffsetContext);

export default PageOffsetProvider;

const parseUserName = (firstName: string, lastName: string): string | null => {
  if (firstName === '' && lastName === '') return null;
  if (firstName === '') return lastName;
  if (lastName === '') return firstName;

  const fullName = `${firstName} ${lastName}`;

  return fullName.length > 22 ? firstName : fullName;
};

export default parseUserName;

import {
  headerHeight,
  sectionMarginTopFromM,
  sectionMarginTopUntilM,
} from '@collab/style/customThemeTokens';

const MAX_RETRIES = 2;

export const defaultScrollOffset = (isMobile: boolean) =>
  isMobile
    ? headerHeight.untilL + sectionMarginTopUntilM
    : headerHeight.fromL + sectionMarginTopFromM;

export const useScrollToId = (scrollOffset?: number) => {
  return async (id: string, isMobile = false) => {
    const element = await findElement(id);

    const offset =
      element.offsetTop - (scrollOffset ?? defaultScrollOffset(isMobile));

    window.scrollTo({
      top: offset,
      behavior: 'smooth',
    });

    const url = `${window.location.pathname.replace(/\/$/, '')}/#${id}`;

    const state = {
      tocState: true,
      url,
    };

    window.history.pushState(state, '', url);
  };
};

const findElement = async (
  id: string,
  count: number = 0,
): Promise<HTMLElement> => {
  if (count === MAX_RETRIES) {
    const h1Label = document.querySelector('h1')?.innerText;

    throw new NoElementFound(id, h1Label);
  }

  const element = document.getElementById(id);

  if (!element) {
    // Tries to avoid a crash if the user navigates via the menu before the page has rendered.
    await sleep(50);
    return findElement(id, count + 1);
  }

  return element;
};

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

class NoElementFound extends Error {
  constructor(id: string, pageH1?: string) {
    super(`Could not find element with id "${id}", pageH1 "${pageH1}"`);
  }
}

import { createContext, useContext } from 'react';

export type NavMenuStyles = {
  desktop: DesktopStyles;
  toc: TocStyles;
};

type TocStyles = {
  topOffset: number;
  scrollOffset: number;
};

type DesktopStyles = {
  topOffset: number;
};

const TocStylesContext = createContext<TocStyles>({} as TocStyles);

const DesktopMenuStylesContext = createContext<DesktopStyles>(
  {} as DesktopStyles,
);

const NavMenuStylesProvider: React.FC<{
  styles: NavMenuStyles;
  children?: React.ReactNode;
}> = ({ styles, children }) => (
  <DesktopMenuStylesContext.Provider value={styles.desktop}>
    <TocStylesContext.Provider value={styles.toc}>
      {children}
    </TocStylesContext.Provider>
  </DesktopMenuStylesContext.Provider>
);

export const useTocStyles = (): TocStyles => useContext(TocStylesContext);

export const useDesktopMenuStyles = (): DesktopStyles =>
  useContext(DesktopMenuStylesContext);

export default NavMenuStylesProvider;

import { useDrawerActions } from '../DrawerProvider';
import MenuAccountItem from '../MenuAccountItem';
import { AccountProps } from '../MenuState';
import {
  useAccountItem,
  useIsRootItem,
  useItemGuard,
  useItemState,
  useMenuStateActions,
} from '../hooks';
import {
  ModuleItem as ModuleItemType,
  PageItem as PageItemType,
  SectionItem as SectionItemType,
  SectionSubItem,
} from '../types';
import { CategoryLabel, PageLabel, ParentLabel } from './MobileMenuItemLabel';
import { useMobileMenuActions } from './hooks';

export const PageItem: React.FC<PageItemType> = ({
  label,
  id,
  href,
  isProtected,
}) => {
  const { open, active } = useItemState(id);
  const { closeOverlays } = useMobileMenuActions();
  const { toggleSubMenuOpen } = useMenuStateActions();
  const { protect, shouldProtect } = useItemGuard(isProtected);

  const shouldNavigate = !active;

  const onClick = () => {
    if (shouldProtect) {
      protect(href);
    } else {
      if (shouldNavigate) {
        closeOverlays();
      } else {
        toggleSubMenuOpen(id);
        closeOverlays();
      }
    }
  };

  return (
    <PageLabel
      href={shouldNavigate ? href : undefined}
      isHighlighted={open || active}
      isProtected={shouldProtect}
      onClick={onClick}
      label={label}
    />
  );
};

export const SectionItem: React.FC<SectionItemType> = ({
  id,
  label,
  items,
  isProtected,
}) => {
  const { protect, shouldProtect } = useItemGuard(isProtected);
  const { open, active } = useItemState(id);
  const { toggleSubMenuOpen } = useMenuStateActions();
  const { addDrawer } = useDrawerActions();
  const isRootItem = useIsRootItem(id);

  const firstPage = isRootItem ? getPageItemIfFirst(items) : undefined;

  const onClick = () => {
    if (shouldProtect && firstPage) {
      protect(firstPage.href);
    } else {
      toggleSubMenuOpen(id);
      addDrawer(id);
    }
  };

  return (
    <ParentLabel
      onClick={onClick}
      isHighlighted={open || active}
      isProtected={shouldProtect}
      label={label}
    />
  );
};

export const ModuleItem: React.FC<ModuleItemType> = ({ id, label }) => {
  const { open, active } = useItemState(id);
  const { toggleSubMenuOpen } = useMenuStateActions();
  const { addDrawer } = useDrawerActions();

  const onClick = () => {
    toggleSubMenuOpen(id);
    addDrawer(id);
  };

  return (
    <ParentLabel
      onClick={onClick}
      isHighlighted={open || active}
      label={label}
    />
  );
};

export const AccountItem: React.FC<{
  account: AccountProps;
}> = ({ account }) => {
  const item = useAccountItem();
  const { active } = useItemState(item.id);
  const { closeOverlays } = useMobileMenuActions();
  const { toggleSubMenuOpen } = useMenuStateActions();

  const onClick = () => {
    if (active) {
      toggleSubMenuOpen(item.id);
    }

    closeOverlays();
  };

  return <MenuAccountItem active={active} onClick={onClick} {...account} />;
};

export const CategoryItem: React.FC<{
  label: string;
}> = ({ label }) => (
  <div className="mt-32 mb-16">
    <CategoryLabel label={label} />
  </div>
);

const getPageItemIfFirst = (items: SectionSubItem[]) =>
  items[0]?.variant === 'page' ? items[0] : undefined;

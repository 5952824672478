import { useEffect, useState } from 'react';

/**
 * @returns The index of the ref in refs that the screen is currently scrolled to.
 */
const useScrollSection = (
  refs: NodeListOf<Element> | undefined,
  anchorOffset: number,
) => {
  const [section, setSection] = useState(0);

  useEffect(() => {
    if (!refs) {
      return;
    }

    const observerOptions: IntersectionObserverInit = {
      threshold: [0, 1],
      rootMargin: `-${anchorOffset + 1}px 0px -50% 0px`,
    };

    const refsArray = Array.from(refs);

    const observerCallback: IntersectionObserverCallback = () => {
      if (anchorOffset > window.scrollY) {
        setSection(0);
        return;
      }

      refsArray.forEach((ref, index, array) => {
        const bounds = ref.getBoundingClientRect();
        const nextRef = array[index + 1];
        const nextRefsVBounds = nextRef
          ? nextRef.getBoundingClientRect().top
          : window.innerHeight;

        if (anchorOffset + 1 > bounds.top && anchorOffset < nextRefsVBounds) {
          setSection(index);
        }
      });
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions,
    );

    refsArray.forEach((ref) => {
      observer.observe(ref);
    });

    if (anchorOffset > window.scrollY) {
      setSection(0);
    }

    return () => {
      observer.disconnect();
    };
  }, [refs, anchorOffset]);

  return section;
};

export default useScrollSection;

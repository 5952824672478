import { useContext } from 'react';

import { useMenuState, useOpenMenuItems } from '../hooks';
import { ModuleItem } from '../types';
import { ToCSectionContext } from './DesktopMenuState';

export const useOpenModule = () => {
  const openIds = useOpenMenuItems();
  const { itemsFlat } = useMenuState();

  return openIds
    .map((id) => itemsFlat.get(id))
    .find((item): item is ModuleItem => item?.variant === 'module');
};

export const useToCSection = () => useContext(ToCSectionContext);

import { cssJoin } from '@volvo-cars/css/utils';
import React, {
  CSSProperties,
  ReactElement,
  RefObject,
  useEffect,
  useRef,
  useState,
} from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { useDesktopMenuStyles } from '../../MenuStyleProvider';
import menuStyles from '../Menu.module.css';
import { CloseModuleItem } from '../MenuItem';
import styles from './DrawerContainer.module.css';

const Drawer: React.FC<{
  items: ReactElement[];
  accountHeight: number;
  menuContainerRef: RefObject<HTMLDivElement>;
}> = ({ items, accountHeight, menuContainerRef }) => {
  const nodeRef = useRef(null);
  const [hasScrolledFromTop, setHasScrolledFromTop] = useState(false);
  const desktopStyles = useDesktopMenuStyles();

  useEffect(() => {
    if (menuContainerRef.current) {
      setHasScrolledFromTop(
        menuContainerRef.current.getBoundingClientRect().top <
          desktopStyles.topOffset,
      );
    }
  }, [menuContainerRef, items, desktopStyles]);

  const withHardTransition = (index: number) =>
    index !== items.length - 1 && hasScrolledFromTop;

  const level = items.length - 1;

  return (
    <div
      className={styles.drawerContainer}
      style={{ '--level': level } as CSSProperties}
    >
      <TransitionGroup component={null}>
        {items.map((rootDrawer, index) => {
          const isActiveDrawer = index + 1 === items.length;

          return (
            <CSSTransition nodeRef={nodeRef} timeout={600} key={index}>
              {(state) => (
                <div
                  ref={nodeRef}
                  style={{
                    opacity: state.startsWith('enter') ? 1 : 0,
                    height: withHardTransition(index) ? 0 : '100%',
                    visibility: isActiveDrawer ? 'visible' : 'hidden',
                    width: '100%',
                  }}
                >
                  {index !== 0 && <CloseModuleItem />}
                  <div
                    className={cssJoin(
                      styles.drawerBlock,
                      menuStyles.thinScrollbar,
                    )}
                    style={
                      {
                        '--max-height': `calc(var(--c-menu-height-offset) + ${accountHeight}px)`,
                        '--padding-bottom':
                          index !== 0
                            ? 'var(--c-space-96)'
                            : 'var(--v-space-48)',
                      } as CSSProperties
                    }
                  >
                    {rootDrawer}
                  </div>
                </div>
              )}
            </CSSTransition>
          );
        })}
      </TransitionGroup>
    </div>
  );
};

export default Drawer;

import styles from './SkipNavLink.module.css';

const SkipNavLink: React.FC<{
  targetId: string;
  children: string;
}> = ({ targetId, children }) => (
  <div className={styles.wrapper}>
    <a
      href={`#${targetId}`}
      onClick={(e) => {
        e.preventDefault();
        focusOnId(targetId);
      }}
      className="button-filled"
      data-color="accent"
    >
      {children}
    </a>
  </div>
);

const focusOnId = (id: string) => {
  const target = document.getElementById(id);

  if (target) {
    target.tabIndex = -1;
    target.focus();

    window.scrollTo({ top: 0 });
  }
};

export default SkipNavLink;

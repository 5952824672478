import { cssJoin } from '@volvo-cars/css/utils';

import { Link, PulseAnimation } from '@collab/atoms';
import UserAvatar from '@collab/atoms/UserAvatar';

import { AccountProps, UserProfile } from '../MenuState';
import menuStyles from '../desktop/Menu.module.css';
import LinkChevron from '../icons/LinkChevron';
import { accountPageLink } from '../models';
import styles from './MenuAccountItem.module.css';
import parseUserName from './parseUserName';

type MenuAccountItemProps = AccountProps & {
  active: boolean;
  onClick: () => void;
};

const MenuAccountItem: React.FC<MenuAccountItemProps> = ({
  login,
  signUp,
  user: userData,
  ...props
}) => {
  if (userData === 'error') {
    return <Error {...props} />;
  }

  if (userData === 'loading') {
    return <Loading />;
  }

  if (userData === 'logged-out') {
    return <LoggedOut login={login} signUp={signUp} />;
  }

  return <LoggedIn user={userData} {...props} />;
};

export const LoggedOut: React.FC<{
  login: () => void;
  signUp: () => void;
}> = ({ login, signUp }) => (
  <div className={styles.accountItem}>
    <div className={styles.accountItemInner}>
      <div>
        <button
          type="button"
          onClick={() => login()}
          className={styles.accountLink}
        >
          Log in
          <LinkChevron />
        </button>
        <p style={{ fontSize: 'var(--v-font-14-size)' }}>
          or{' '}
          <button
            type="button"
            onClick={() => signUp()}
            className={styles.signUp}
          >
            <p className="font-medium">Sign up</p>
          </button>
        </p>
      </div>
    </div>
  </div>
);

export const LoggedIn: React.FC<{
  active: boolean;
  onClick: () => void;
  logout: () => void;
  user: UserProfile;
}> = ({ active, onClick, logout, user }) => {
  const onLogout = (e: React.MouseEvent) => {
    e.stopPropagation(); // Don't toggle ToC
    e.preventDefault(); // Don't follow link

    logout();
  };

  const shouldNavigate = !active;
  const userName = parseUserName(user.firstName, user.lastName);

  const accountItemInner = (
    <div className={styles.accountItemInner}>
      <div style={{ overflow: 'hidden' }}>
        <p className={cssJoin(styles.userName, 'font-medium')}>
          {userName ?? 'Account'}
        </p>
        <p className={styles.logOutTextSpace}>&nbsp;</p>
      </div>
      <div>
        <UserAvatar
          firstName={user.firstName}
          lastName={user.lastName}
          imageSrc={user.imageUrl}
          darker={!active}
        />
      </div>
    </div>
  );

  const yourAccountStyle = cssJoin(
    menuStyles.focusVisibleOutline,
    styles.accountItem,
    styles.accountItemHover,
    active && styles.active,
  );

  return (
    <div style={{ position: 'relative' }}>
      {shouldNavigate ? (
        <Link
          title="Your account"
          aria-label="account"
          href={accountPageLink}
          className={yourAccountStyle}
        >
          {accountItemInner}
        </Link>
      ) : (
        <button
          title="Your account"
          aria-label="account"
          type="button"
          onClick={onClick}
          className={yourAccountStyle}
        >
          {accountItemInner}
        </button>
      )}
      <div className={styles.logOutPlacement}>
        <LogOut onLogout={onLogout} />
      </div>
    </div>
  );
};

export const Loading = () => (
  <div className={styles.accountItem}>
    <div className={styles.accountItemInner}>
      <div className={styles.loadingTextContainer}>
        <PulseAnimation height="var(--v-space-16)" maxWidth="70%" />
        <PulseAnimation height="var(--v-space-8)" maxWidth="30%" />
      </div>
      <div className={styles.loadingAvatar}>
        <PulseAnimation height="var(--v-space-32)" />
      </div>
    </div>
  </div>
);

export const Error: React.FC<{
  logout: () => void;
}> = ({ logout }) => {
  const onLogout = (e: React.MouseEvent) => {
    e.stopPropagation();

    logout();
  };

  return (
    <div className={styles.accountItem}>
      <div className={styles.accountItemInner}>
        <div>
          <p className={cssJoin('font-medium', styles.userName)}>
            Your account
          </p>
          <LogOut onLogout={onLogout} />
        </div>
      </div>
    </div>
  );
};

const LogOut: React.FC<{
  onLogout: (e: React.MouseEvent) => void;
}> = ({ onLogout }) => (
  <button type="button" onClick={onLogout} style={{ width: 'fit-content' }}>
    <p className={cssJoin('font-medium', styles.logOutText)}>Log out</p>
  </button>
);

export default MenuAccountItem;

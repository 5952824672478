import React, { CSSProperties, ReactNode } from 'react';
import { Spacer } from 'vcc-ui';

import { Toasts } from '@collab/features/toasts';
import { Footer, FooterProps, Header, HeaderProps } from '@collab/organisms';
import { footerHeight } from '@collab/organisms/Footer/Footer';
import NavMenu from '@collab/organisms/NavMenu';
import { NavMenuStyles } from '@collab/organisms/NavMenu/MenuStyleProvider';
import PageOffsetProvider from '@collab/providers/PageOffsetProvider';
import {
  anchorOffsetUntilL,
  headerHeight,
  infoBannerHeight,
} from '@collab/style/customThemeTokens';

import styles from './PageLayout.module.css';

const layoutSpacers = {
  untilL: 192,
  fromL: 128,
};

const pageOffset = {
  small: headerHeight.untilL + footerHeight.untilM + layoutSpacers.untilL,
  medium: headerHeight.untilL + footerHeight.fromM + layoutSpacers.untilL,
  large: headerHeight.fromL + footerHeight.fromM + layoutSpacers.fromL,
};

const PageLayout: React.FC<{
  headerProps: HeaderProps;
  footerProps: FooterProps;
  showInfoBanner?: boolean;
  children: ReactNode;
}> = ({ headerProps, footerProps, showInfoBanner = false, children }) => (
  <PageOffsetProvider
    headerOffset={getOffset(0, showInfoBanner)}
    titleOffset={getOffset(headerHeight.fromL, showInfoBanner)}
  >
    <div className={`flex-col bg-primary ${styles.page}`}>
      <Header {...headerProps} />
      <Spacer size={[8, 8, 0]} />
      <div
        className={styles.content}
        style={
          {
            '--offset-small': `${pageOffset.small}px`,
            '--offset-medium': `${pageOffset.medium}px`,
            '--offset-large': `${pageOffset.large}px`,
          } as CSSProperties
        }
      >
        <div className={styles.pageLayout}>
          <main className={styles.innerContent} id="main">
            {children}
          </main>
          <NavMenu styles={getMenuStyles(showInfoBanner)} />
        </div>
        <Toasts />
      </div>
      <Spacer size={16} />
      <Footer {...footerProps} />
    </div>
  </PageOffsetProvider>
);

const getMenuStyles = (hasBannerOffset: boolean): NavMenuStyles => ({
  toc: {
    topOffset: getOffset(headerHeight.untilL, hasBannerOffset),
    scrollOffset: getOffset(anchorOffsetUntilL, hasBannerOffset),
  },
  desktop: {
    topOffset: getOffset(headerHeight.fromL, hasBannerOffset),
  },
});

const getOffset = (value: number, addOffset: boolean) =>
  addOffset ? value + infoBannerHeight : value;

export default PageLayout;

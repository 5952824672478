import { useEffect, useState } from 'react';

const useHeight = (elem: HTMLDivElement | null) => {
  const [height, setHeight] = useState(0);
  useEffect(() => {
    if (!elem) return;

    const observer = new ResizeObserver(([entry]) =>
      setHeight(Math.round(entry.contentRect.height)),
    );
    observer.observe(elem);

    return () => {
      observer.unobserve(elem);
    };
  }, [elem]);

  return height;
};

export default useHeight;

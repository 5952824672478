import { cssJoin } from '@volvo-cars/css/utils';
import { IconButton } from '@volvo-cars/react-icons';

import { TopBarLogo } from '@collab/atoms';

import { useDrawerActions, useDrawers } from '../../DrawerProvider';
import { useMenuStateActions } from '../../hooks';
import { useMobileBreadCrumbs, useMobileMenuActions } from '../hooks';
import styles from './MobileMenuTopBar.module.css';

const MobileMenuTopBar: React.FC = () => {
  const { toggleSubMenuOpen, closeNonActiveItems } = useMenuStateActions();
  const { setMobileMenuOpen, closeOverlays } = useMobileMenuActions();
  const { removeDrawer, resetDrawerDelayed } = useDrawerActions();

  const drawers = useDrawers();
  const activeMenuItemId = drawers[drawers.length - 1];

  const onBackButtonClick = () => {
    removeDrawer();
    toggleSubMenuOpen(activeMenuItemId);
    closeNonActiveItems();
  };

  const onClose = () => {
    resetDrawerDelayed();
    setMobileMenuOpen(false);
  };

  return (
    <div className={styles.topBar}>
      {drawers.length > 0 ? (
        <>
          <BackButton onClick={onBackButtonClick} />
          <Breadcrumbs itemId={activeMenuItemId} />
        </>
      ) : (
        <TopBarLogo onClick={closeOverlays} />
      )}
      <CloseButton onClick={onClose} />
    </div>
  );
};

const Breadcrumbs: React.FC<{
  itemId: string;
}> = ({ itemId }) => {
  const breadcrumbs = useMobileBreadCrumbs(itemId);

  return (
    <div className={styles.breadcrumb}>
      {breadcrumbs.map((crumb, index) => (
        <p
          key={index}
          className={cssJoin(
            styles.breadcrumbText,
            index === breadcrumbs.length - 1 && 'font-medium',
          )}
        >
          {crumb}
        </p>
      ))}
    </div>
  );
};

const BackButton: React.FC<{
  onClick: () => void;
}> = ({ onClick }) => (
  <IconButton
    icon="chevron-back"
    variant="clear"
    onClick={onClick}
    aria-label="Go back"
    bleed
  />
);

const CloseButton: React.FC<{
  onClick: () => void;
}> = ({ onClick }) => (
  <IconButton
    icon="x"
    variant="clear"
    onClick={onClick}
    aria-label="Close menu"
    bleed
  />
);

export default MobileMenuTopBar;

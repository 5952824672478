import { CSSProperties } from 'react';

import styles from './pulseAnimation.module.css';

const PulseAnimation: React.FC<{
  height: string | number;
  maxWidth?: string | number;
}> = ({ height, maxWidth }) => {
  const style = {
    '--height': typeof height === 'number' ? `${height}px` : height,
    ...(maxWidth && {
      '--max-width': typeof maxWidth === 'number' ? `${maxWidth}px` : maxWidth,
    }),
  } as CSSProperties;

  return <div className={styles.pulseAnimation} style={style} />;
};

export default PulseAnimation;

import {
  AccountProps,
  MenuContent,
  MenuStateProvider,
  User,
  UserProfile,
} from './MenuState';
import NavMenu from './NavMenu';
import { useMenuStateActions } from './hooks';
import { useMobileMenuActions, useMobileMenuContainer } from './mobile/hooks';
import {
  AccountItemModel,
  CategoryItemModel,
  createAccountItem,
  MenuItemModel,
  ModuleItemModel,
  ModuleSubItemModel,
  PageItemModel,
  PageTopicItemModel,
  SectionItemModel,
  SectionSubItemModel,
} from './models';
import {
  MenuStateItem,
  ModuleItem,
  PageItem,
  PageTopicItem,
  SectionItem,
  SectionSubItem,
} from './types';
import { isPageItem, isParentItem } from './utils/isItemType';
import { labelsToTopics } from './utils/labelsToTopics';

export type {
  AccountProps,
  ModuleSubItemModel,
  AccountItemModel,
  MenuItemModel,
  MenuStateItem,
  PageTopicItem,
  PageItem,
  SectionItem,
  ModuleItem,
  PageItemModel,
  SectionItemModel,
  ModuleItemModel,
  CategoryItemModel,
  PageTopicItemModel,
  SectionSubItem,
  MenuContent,
  User,
  UserProfile,
  SectionSubItemModel,
};

export {
  labelsToTopics,
  useMobileMenuActions,
  useMobileMenuContainer,
  MenuStateProvider,
  createAccountItem,
  useMenuStateActions,
  isParentItem,
  isPageItem,
};

export default NavMenu;

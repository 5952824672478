import { CSSProperties, useState } from 'react';

import styles from './userAvatar.module.css';

const UserAvatar: React.FC<{
  firstName: string;
  lastName: string;
  darker?: boolean;
  imageSrc: string | null;
}> = ({ firstName, lastName, darker = false, imageSrc }) => {
  const [src, setSrc] = useState(imageSrc);

  return (
    <div
      className={styles.avatar}
      style={
        {
          '--bg-color': darker && 'var(--v-color-ornament-primary)',
        } as CSSProperties
      }
    >
      {src ? (
        <img
          src={src}
          alt={`Profile for ${firstName} ${lastName}`}
          onError={() => setSrc(null)}
        />
      ) : (
        <b className={`${darker ? 'text-primary' : 'text-secondary'} micro`}>
          {getInitials(firstName, lastName)}
        </b>
      )}
    </div>
  );
};

const getInitials = (firstName: string, lastName: string) =>
  `${getInitial(firstName)}${getInitial(lastName)}`;

const getInitial = (name: string) => {
  const [firstWordInName] = name.split(' ');

  return firstWordInName.charAt(0);
};

export default UserAvatar;

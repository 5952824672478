import { TrackingProvider } from '@volvo-cars/tracking';
import React, { CSSProperties, useRef } from 'react';

import useHeight from '@collab/hooks/useHeight';

import DrawerProvider, { useDrawers } from '../DrawerProvider';
import { useDesktopMenuStyles } from '../MenuStyleProvider';
import { useAccount, useMenuItems, useMenuState } from '../hooks';
import { ModuleItem } from '../types';
import DesktopMenuProvider from './DesktopMenuState';
import DrawerContainer from './DrawerContainer';
import styles from './Menu.module.css';
import {
  AccountItem,
  ModuleMenuItem,
  PageMenuItem,
  SectionMenuItem,
} from './MenuItem';
import { CategoryLabel } from './MenuItemLabel';
import { useOpenModule } from './hooks';

const DesktopMenuComponent = () => {
  const menuContainerRef = useRef<HTMLDivElement>(null);
  const menuAccountRef = useRef<HTMLDivElement>(null);
  const drawers = useDrawers();
  const account = useAccount();
  const accountHeight = useHeight(menuAccountRef.current);
  const desktopMenuStyles = useDesktopMenuStyles();

  const drawerItems = drawers.map((id) => <SubMenuDrawer id={id} key={id} />);

  const topOffset = desktopMenuStyles.topOffset;

  return (
    <DesktopMenuProvider>
      <TrackingProvider eventCategory="navigation menu">
        <nav
          style={{ '--top-offset': `${topOffset}px` } as CSSProperties}
          className={styles.menuBase}
          ref={menuContainerRef}
          aria-label="Primary"
        >
          {account && (
            <div className={styles.alignWithScrollbars} ref={menuAccountRef}>
              <AccountItem account={account} />
            </div>
          )}
          <div className={styles.topFade} />
          <DrawerContainer
            menuContainerRef={menuContainerRef}
            accountHeight={accountHeight}
            items={[<RootDrawer key={0} />, ...drawerItems]}
          />
        </nav>
      </TrackingProvider>
    </DesktopMenuProvider>
  );
};

const RootDrawer = () => {
  const items = useMenuItems();

  if (!items) {
    throw new MissingContext();
  }

  return (
    <>
      {items.map((item) => {
        if (item.variant === 'category') {
          return <CategoryLabel key={item.id} label={item.label} />;
        }

        if (item.variant === 'page') {
          return <PageMenuItem key={item.id} {...item} />;
        }

        if (item.variant === 'module') {
          return <ModuleMenuItem key={item.id} {...item} />;
        }

        return <SectionMenuItem key={item.id} {...item} />;
      })}
    </>
  );
};

const SubMenuDrawer: React.FC<{
  id: string;
}> = ({ id }) => {
  const { itemsFlat } = useMenuState();
  const item = id ? (itemsFlat.get(id) as ModuleItem) : undefined;

  return (
    <>
      {item?.items.map((item) => {
        if (item.variant === 'page') {
          return <PageMenuItem key={item.id} {...item} />;
        }

        return <SectionMenuItem key={item.id} {...item} />;
      })}
    </>
  );
};

const DesktopMenu = () => {
  const openModule = useOpenModule();

  return (
    <DrawerProvider initialState={openModule ? [openModule.id] : []}>
      <DesktopMenuComponent />
    </DrawerProvider>
  );
};

class MissingContext extends Error {
  constructor() {
    super('No context defined. Wrap menu in a MenuStateProvider.');
  }
}

export default DesktopMenu;

import { cssJoin } from '@volvo-cars/css/utils';
import { TrackingProvider } from '@volvo-cars/tracking';
import React, { useEffect } from 'react';

import { zIndex } from '@collab/style/customThemeTokens';

import DrawerProvider, {
  useDrawerActions,
  useDrawers,
} from '../DrawerProvider';
import {
  useAccount,
  useActiveItemId,
  useContentState,
  useMenuItems,
  useMenuState,
  useMenuStateActions,
} from '../hooks';
import {
  ModuleItem as ModuleItemType,
  PageItem as PageItemType,
  SectionItem as SectionItemType,
} from '../types';
import { createParentIds } from '../utils/menuIds';
import MobileBackdrop from './MobileBackdrop';
import DrawerContainer from './MobileDrawerContainer';
import styles from './MobileMenu.module.css';
import {
  AccountItem,
  CategoryItem,
  ModuleItem,
  PageItem,
  SectionItem,
} from './MobileMenuItem';
import { ExternalMenuItemLabel } from './MobileMenuItemLabel';
import MobileMenuTopBar from './MobileMenuTopBar/MobileMenuTopBar';
import TableOfContentBar from './TableOfContentBar';
import {
  useMobileMenuActions,
  useMobileMenuContainer,
  useMobileMenuToC,
} from './hooks';

export const menuToggleDuration = 500;

const MobileMenu = () => {
  const activeId = useActiveItemId();
  const initialState = activeId ? createParentIds(activeId) : [];

  return (
    <DrawerProvider initialState={initialState}>
      <MobileMenuContainer />
      <MobileMenuBackdrop />
      <TableOfContentBar />
    </DrawerProvider>
  );
};

const MobileMenuContainer = () => {
  const account = useAccount();
  const isOpen = useMobileMenuContainer();
  const drawers = useDrawers();
  const { closeNonActiveItems } = useMenuStateActions();
  const activeId = useActiveItemId();

  useEffect(() => {
    if (isOpen) {
      closeNonActiveItems();
    }
  }, [isOpen, closeNonActiveItems, activeId]);

  const drawerItems = drawers.map((id) => <SubMenuDrawer key={id} id={id} />);

  return (
    <nav
      className={cssJoin(styles.mobileMenuContainer, isOpen && styles.isOpen)}
      style={{
        transition: `transform ${menuToggleDuration}ms ease-in-out`,
        zIndex: zIndex.mobileMenu,
      }}
      aria-label="Primary"
    >
      <TrackingProvider eventCategory="navigation menu mobile">
        <MobileMenuTopBar />
        {account && <AccountItem account={account} />}
        <DrawerContainer
          items={[<RootDrawer key={0} />, ...drawerItems]}
          withAccount={!!account}
        />
      </TrackingProvider>
    </nav>
  );
};

const RootDrawer: React.FC = () => {
  const items = useMobileMenuItems();
  const { heading, footer } = useContentState();

  return (
    <>
      <CategoryItem label={heading} />
      {items.map((item, index) => {
        if (item.variant === 'page') {
          return <PageItem key={index} {...item} />;
        }

        return <SectionItem key={index} {...item} />;
      })}
      <CategoryItem label={footer.heading} />
      <ExternalMenuItemLabel
        href={footer.link.href}
        label={footer.link.label}
      />
    </>
  );
};

const SubMenuDrawer: React.FC<{
  id: string;
}> = ({ id }) => {
  const { itemsFlat } = useMenuState();
  const item = id
    ? (itemsFlat.get(id) as SectionItemType | ModuleItemType)
    : undefined;

  return (
    <>
      {item?.items.map((item, index) => {
        if (item.variant === 'page') {
          return <PageItem key={index} {...item} />;
        }

        if (item.variant === 'module') {
          return <ModuleItem key={index} {...item} />;
        }

        if (item.variant === 'section') {
          return <SectionItem key={index} {...item} />;
        }

        return <CategoryItem key={index} label={item.label} />;
      })}
    </>
  );
};

const MobileMenuBackdrop = () => {
  const isToCOpen = useMobileMenuToC();
  const { setMobileMenuOpen } = useMobileMenuActions();
  const isMobileMenuOpen = useMobileMenuContainer();
  const { resetDrawerDelayed } = useDrawerActions();

  const onClose = () => {
    resetDrawerDelayed();
    setMobileMenuOpen(false);
  };

  return (
    <MobileBackdrop
      hasExistingBackdrop={isToCOpen}
      show={isMobileMenuOpen}
      onClose={onClose}
    />
  );
};

const useMobileMenuItems = () =>
  useMenuItems().filter(
    (item): item is PageItemType | SectionItemType =>
      item.variant !== 'category',
  );

export default MobileMenu;

import { ExtendCSS, Logo } from 'vcc-ui';

import { Click } from '@vcc-www/buttons';

const LANDING_PAGE_PATH = '/';

const TopBarLogo: React.FC<{
  onClick?: () => void;
}> = ({ onClick }) => (
  <Click
    href={LANDING_PAGE_PATH}
    onClick={onClick}
    extend={linkStyle}
    aria-label="Homepage"
    trackEventAction="link|click"
    trackEventLabel={LANDING_PAGE_PATH}
  >
    <Logo type="spreadmark" alt="Volvo" />
  </Click>
);

const linkStyle: ExtendCSS = ({ theme: { baselineGrid } }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignSelf: 'center',
  padding: `${baselineGrid * 3}px ${baselineGrid}px`,
  marginLeft: -1 * baselineGrid,

  '> img': {
    width: 86,
    height: 7,
    fromM: {
      width: 98,
      height: 8,
    },
  },
});

export default TopBarLogo;
